import React, {Component} from 'react';
import {
  Alert,
  Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader,
  Row
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Dropzone from "react-dropzone";
import Cutter from "./cutter";

export default class ArticlesM extends Component {

  _state = {
    loading: false,
    error: null,
    modal: false,
    item: {
      id: 0,
      name: '',
      url: '',
      pic_url: '',
      pic_url_thumb: '',
    },
    file_pic: null
  };

  state = {...this._state};

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  add = () => {
    this.setState({
      ...this._state,
      modal: true,
    });
  };

  edit = (id) => {
    this.setState({
      ...this._state,
      modal: true,
      loading: true,
    });
    Api.get('/articles/form', {id: id}, (rsp) => {
      if (rsp.ok) {
        this.setState({
          loading: false,
          item: rsp.item ? rsp.item : this.state.item,
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
          error: rsp.msg
        });
      }
    });
  };

  save = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true,
      error: null
    });


    const data = new FormData();
    Object.keys(this.state.item).map(key => data.append(key, this.state.item[key]));
    data.append('file_pic', this.state.file_pic);

    Api.post('/articles/create', data, (rsp) => {

      if (rsp.ok) {
        toast.success('Guardado correctamente');
        this.toggle();
        if (typeof this.props.callback === 'function') this.props.callback();
      } else {
        this.setState({
          ...this.state,
          loading: false,
          error: rsp.msg
        });
      }

    });
  };

  remove = () => {
    if (window.confirm('¿Seguro que quieres borrar?')) {
      this.setState({
        ...this.state,
        loading: true,
        error: null
      });
      Api.get('/articles/remove', {id: this.state.item.id}, (rsp) => {
        if (rsp.ok) {
          toast.success('Eliminado correctamente');
          this.toggle();
          if (typeof this.props.callback === 'function') this.props.callback();
        } else {
          toast.error('Se produjo un error');
        }
      }, 'Eliminando...')
    }
  };

  changed = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      item: {
        ...this.state.item,
        [name]: value
      }
    });
  };

  render() {
    const {item} = this.state;
    return (
      <>
        <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}>
          <fieldset disabled={this.state.loading}>
            <ModalHeader toggle={this.toggle}>
              {this.state.item.id > 0 ? 'Editar' : 'Agregar'} Noticia
            </ModalHeader>
            <ModalBody className="form-horizontal">

              <Alert color="danger" isOpen={this.state.error != null} fade={true}>
                {this.state.error}
              </Alert>

              <Row className="form-group">
                <Col md="3">Nombre</Col>
                <Col md="9">
                  <Input name="name" value={this.state.item.name} onChange={this.changed}/>
                </Col>
              </Row>

              <Row className="form-group">
                <Col md="3">Url</Col>
                <Col md="9">
                  <Input name="url" value={this.state.item.url} onChange={this.changed}/>
                </Col>
              </Row>

              <Row className="form-group">
                <Col md="3">Imagen</Col>
                <Col md="9">

                  {this.state.file_pic ?
                    <img
                      src={this.state.file_pic && window.URL.createObjectURL(this.state.file_pic)}
                      style={{maxWidth: '100%', marginBottom: 15, cursor: 'alias'}}
                      /*onClick={() => {
                        this.cutter.open(this.state.file_pic);
                      }}*//>
                    :
                    <a href={item.pic_url} hidden={!item.pic_url} target="_blank">
                      <img src={item.pic_url_thumb} className="mrg-b-15"/>
                    </a>}

                  <Dropzone
                    className="_dropzone"
                    activeClassName="active"
                    rejectClassName="error"
                    disabledClassName="disabled"
                    disabled={this.state.loading}
                    multiple={false}
                    accept="image/*"
                    onDrop={files => {
                      this.setState({
                        file_pic: files[0]
                      });
                      //this.cutter.open(files[0]);
                    }}>
                    <span className="btn btn-outline-dark">Examinar...</span>
                  </Dropzone>

                </Col>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button color="danger" style={{marginRight: 'auto'}}
                      className={this.state.item.id > 0 ? '' : 'none'}
                      outline onClick={this.remove}>
                Eliminar
              </Button>
              <Button color="secondary" outline onClick={this.toggle}>
                Cancelar
              </Button>
              <Button color="primary" onClick={this.save}>
                <i className="fa fa-check"/> Guardar
              </Button>
            </ModalFooter>
          </fieldset>
        </Modal>

        <Cutter ref={ins => this.cutter = ins}
                callback={image => {
                  this.setState({
                    file_pic: image
                  })
                }}/>

      </>
    );
  }
}
