import React, {Component} from "react";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Select from "react-select";

const options = [
  {value: "1", label: "Notificacion"},
  {value: "2", label: "Email"}
];

export default class EventosM extends Component {
  state = {
    item              : {
      id        : 0,
      id_station: 0,
      direction : "",
      name      : "",
      date      : "",
    },
    type_notifications: [],
    stations          : [],
    messages          : [],
    message           : {},
    stationChunks     : [],
    tabIndex          : 0,
    loading           : false,
    error             : "",
    modal             : false
  };

  toggle = () => {
    this.setState({
      ...this.state,
      modal: !this.state.modal
    });
  };

  add = () => {
    this.dataForm(0);
  };

  eliminar = id => this.remove(id);

  remove = id => {
    if (window.confirm("¿Seguro que quieres borrar?")) {

      this.setState({

        loading: true,
        error  : ""
      });

      Api.get(
        "/events/remove",
        {id: id},
        rsp => {
          if (rsp.ok) {
            toast.success("Eliminado correctamente");
            this.state.modal = false;
            // this.toggle();
            if (typeof this.props.callback === "function") this.props.callback();
          } else {
            toast.error("Se produjo un error");
          }
        },
        "Eliminando..."
      );
    }
  };

  edit = id => this.dataForm(id);

  dataForm = id => {
    this.setState({
      ...this.state,
      modal        : false,
      loading      : true,
      station      : {},
      messageType  : {},
      message      : {},
      chunk        : "",
      stationChunks: [],
      item         : {
        id        : 0,
        id_station: 0,
        direction : "",
        type      : 0,
        name      : "",
        date      : ""
      },

      type_notifications: [],
      stations          : [],
      allMessages       : [],
      messages          : [],
      messageTypes      : [],
    });

    Api.get("/events/dataForm", {id: id}, rsp => {
      if (rsp.ok) {
        let options = [];
        rsp.type_notifications.map(o =>
          options.push({
            value: o.id,
            label: o.placa
          })
        );

        this.mapDataToState(rsp);
        this.setState({
          loading: false,
          modal  : true
        });

      } else {
        this.setState({
          ...this.state,
          loading: false,
          error  : rsp.msg
        });
      }
    });
  };

  save = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true,
      error  : ""
    });

    Api.post("/events/create", this.state.item, rsp => {
      if (rsp.ok) {
        toast.success("Notificación Enviada");
        this.toggle();
        if (typeof this.props.callback === "function") this.props.callback();
      } else {
        this.setState({
          ...this.state,
          loading: false,
          error  : rsp.msg
        });
      }
    });
  };


  changed = e => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      item: {
        ...this.state.item,
        [name]: value
      }
    });
  };

  // Mappers
  mapDataToState = data => {
    this.mapStationsToState(data.stations);
    this.mapMessagesToState(data.messages);
    this.mapMessageTypesToState(data.type_notifications);
  };

  mapStationsToState = data => {
    let stations = [];
    data.map(current => {
      stations.push({
        id     : current.id,
        value  : current.id,
        name   : current.name,
        label  : current.name,
        address: current.address
      });
    });

    this.setState({stations});
  };

  mapMessagesToState = data => {
    let allMessages = [];
    data.map(current => {
      allMessages.push({
        id          : current.id,
        value       : current.id,
        label       : current.message,
        message     : current.message,
        notification: current.notificacion,
        parent_type : current.parent_type,
        select      : current.select
      });
    });

    this.setState({allMessages});
  };

  mapMessageTypesToState = data => {
    let messageTypes = [];
    data.map(current => {
      messageTypes.push({
        id          : current.id,
        value       : current.id,
        label       : current.notificacion,
        notification: current.notificacion
      });
    });
    this.setState({messageTypes});
  };

  // Event Handlers
  handleSelectStation = station => {
    this.setState({station});
  };

  handleSelectMessageType = selectedOption => {
    let messages = this.state.allMessages.filter(
      item => selectedOption.id == item.parent_type
    );

    this.setState({
      messageType  : selectedOption,
      message      : {},
      stationChunks: [],
      messages
    });
  };

  handleSelectMessage = message => {
    this.setState({message});
    this.evaluateChunk(message.select);
    this.evaluateStationChunks(message);
  };

  handleSelectOption = chunk => {
    this.setState({chunk});
  };

  handleSubmit = () => {

    console.log('messageType', this.state.messageType);

    let messageBuilt = '';

    console.log('this.state.messageType.label', this.state.messageType.label);

    if (this.state.messageType.label === 'PERSONALIZADO') {
      messageBuilt = this.state.item.name;
      console.log('llego aca', messageBuilt);
    } else {
      messageBuilt = this.buildMessage();

      if (this.state.stationChunks.length > 0) {
        let messageParts = messageBuilt.split('EEE');
        messageBuilt = messageParts[0];
        let areAllChunksSelected = true;
        this.state.stationChunks.map((c, i) => {
          if (!c.value)
            areAllChunksSelected = false;
          messageBuilt += (c.label || 'EEE') + messageParts[i + 1];
        });
        if (!areAllChunksSelected) {
          toast.error("Seleccione las Estaciones");
          return;
        }
      }
    }

    console.log('messageBuilt', messageBuilt);

    let data = {
      id_station      : this.state.station.id,
      type            : this.state.messageType.id,
      direction       : this.state.station.name,
      name            : messageBuilt,
    };

    this.setState({loading: true});

    Api.get("/events/create", data, rsp => {
      this.setState({loading: false});
      if (rsp.ok) {
        toast.success("Notificación Enviada");
        this.toggle();
        if (typeof this.props.callback === "function")
          this.props.callback();
      } else {
        toast.error(rsp.msg);
      }
    }, "Guardando...");

    console.log(data);
  };

  // Builders
  buildMessage = () => {
    let aux = this.state.message.label || '';
    return aux.replace('XXX', this.state.chunk);
  }

  evaluateChunk = select => {
    let aux = select || '';
    let chunk = aux.length > 0 ? select.split(',')[0] : '';
    this.setState({chunk});
  }

  evaluateStationChunks = message => {
    let aux = message.label || '';
    let countStationChunks = (aux.match(/EEE/g) || []).length;
    let stationChunks = [];
    for (let i = 0; i < countStationChunks; i++)
      stationChunks.push({});
    this.setState({stationChunks});
  }

  // Renders
  renderMessages = () => {
    let aux = [];

    this.state.messages.forEach(element => {
      let classes = element == this.state.message ? "active" : "";
      aux.push(
        <p
          className={`list ${classes}`}
          onClick={() => this.handleSelectMessage(element)}
        >
          {element.message}
        </p>
      );
    });

    return (
      <Row className="form-group" style={{display: aux.length > 0 ? "block" : "none"}}>
        <Col md="12">
          Mensajes <br/>
        </Col>
        <Col md="12">
          <div className="messages-box">{aux}</div>
        </Col>
      </Row>
    );
  };

  renderStationOptions = () => {
    let stationOptions = [];
    this.state.stationChunks.map((c, i) => {
      stationOptions.push(
        <Col md="4" style={{marginTop: "5px"}}>
          <Select
            value={c.value ? c : null}
            options={this.state.stations}
            placeholder={"Estación " + (i + 1)}
            onChange={o => {
              c.value = o.value;
              c.label = o.label;
              this.setState(this.state);
            }}
          />
        </Col>
      );
    });

    return (
      <Row className="form-group" style={{display: stationOptions.length > 0 ? "block" : "none"}}>
        <Col md="12">
          Opciones Estaciones <br/>
        </Col>
        <Col md="12">
          <Row>
            {stationOptions}
          </Row>
        </Col>
      </Row>
    );
  }

  renderOptions = () => {
    let aux = [];
    let iterator = this.state.message.select || "";
    iterator = iterator.length > 0 ? iterator.split(",") : [];

    iterator.forEach(element => {
      let classes = element == this.state.chunk ? "active" : "";
      aux.push(
        <button
          className={`btn btn-outline-secondary btn-select ${classes}`}
          onClick={() => this.handleSelectOption(element)}
        >
          {element}
        </button>
      );
    });

    return (
      <Row className="form-group" style={{display: iterator.length > 0 ? "block" : "none"}}>
        <Col md="12">
          Opciones <br/>
        </Col>
        <Col md="12" className="text-center">
          {aux}
        </Col>
      </Row>
    );
  };

  renderPreview = () => {
    let empty = Object.keys(this.state.message).length == 0;

    let chunk = this.state.chunk;
    let messageBuilt = this.buildMessage().replace(chunk, `<span> ${chunk} </span>`);

    messageBuilt = messageBuilt.replace(/EEE/g, `<span>EEE</span>`);
    if (this.state.stationChunks.length > 0) {
      let messageParts = messageBuilt.split('EEE');
      messageBuilt = messageParts[0];
      this.state.stationChunks.map((c, i) => {
        messageBuilt += (c.label || 'EEE') + messageParts[i + 1];
      });
    }

    return (
      <Row className="form-group" style={{display: empty ? "none" : "block"}}>
        <Col md="12">
          Vista Previa <br/>
        </Col>
        <Col
          md="12"
          className="preview text-center">
          <p dangerouslySetInnerHTML={{__html: messageBuilt}}></p>
        </Col>
      </Row>
    )
  };

  render() {
    return (
      <Modal
        isOpen={this.state.modal}
        className={this.props.className}
        backdrop="static"
        keyboard={false}
        size="lg">
        <fieldset disabled={this.state.loading}>
          <ModalHeader toggle={this.toggle}>
            {this.state.item.id > 0 ? "Editar" : "Agregar"} Notificación
          </ModalHeader>
          <ModalBody className="form-horizontal">
            <Alert color="danger" isOpen={this.state.error != ""} fade={true}>
              {this.state.error}
            </Alert>

            <Row className="form-group">
              <Col md="4">Opcion de envio </Col>
              <Col md="8">
                <Select
                  name="send_option_id"
                  value={this.state.messageType}
                  options={this.state.messageTypes}
                  onChange={this.handleSelectMessageType}
                  isMulti={false}
                  placeholder="Buscar..."
                />
              </Col>
            </Row>

            {this.state.messageType && this.state.messageType.label === 'PERSONALIZADO' && (
              <Row>
                <Col md="4">Mensaje</Col>
                <Col md="8">
                  <Input name="name" type="textarea" value={this.state.item.name} onChange={this.changed}/>
                </Col>
              </Row>
            )}

            {this.renderMessages()}
            {this.renderStationOptions()}
            {this.renderOptions()}
            {this.renderPreview()}

          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              style={{marginRight: "auto"}}
              className={this.state.item.id > 0 ? "" : "none"}
              outline
              onClick={this.remove}>
              Eliminar
            </Button>
            <Button color="secondary" outline onClick={this.toggle}>
              Cancelar
            </Button>
            <Button color="primary" onClick={this.handleSubmit}>
              <i className="fa fa-check"/> Enviar Notificación
            </Button>
          </ModalFooter>
        </fieldset>
      </Modal>
    );
  }
}
