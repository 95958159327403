import React from 'react';
import {
  Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Label
} from 'reactstrap';
import Fils from "./widgets/fils";
import * as queryString from "query-string";
import {Api} from "../inc/api";
import {User} from "./../inc/user";

export default class Ratings extends React.Component {

  state = {
    fils: {
      date_from   : '',
      date_to     : '',
      stars       : '',
      with_comment: false,
    },
  };

  changed = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      fils: {
        ...this.state.fils,
        [name]: value
      }
    });
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>
            <span className="text-uppercase bold">Calificaciones</span>
            <div className="card-header-actions">
              <button className="bold text-uppercase btn btn-primary" onClick={() => {
                let all_fils = this.fils.getAllFils();
                all_fils.export = 1;
                all_fils.token = User.token;

                let query_fils = queryString.stringify(all_fils);

                console.log('exportar: ', query_fils);

                window.open(Api.URL_BASE + '/ratings?export=1&' + query_fils, '_blank');
              }}>
                <i className="fa fa-download"/> Exportar
              </button>
            </div>
          </CardHeader>
          <CardBody className="pdg-5">

            <Fils {...this.props}
                  customFils={this.state.fils}
                  form={() => (
                    <>
                      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label className="mr-sm-2">Rango de fechas</Label>
                        <Input name="date_from"
                               value={this.state.fils.date_from}
                               type="date"
                               onChange={this.changed}/>
                        <div className="pdg-l-5 pdg-r-5">a</div>
                        <Input name="date_to"
                               value={this.state.fils.date_to}
                               type="date"
                               onChange={this.changed}/>
                      </FormGroup>
                      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label className="mr-sm-2">Estrellas</Label>
                        <Input type="select"
                               name="stars"
                               value={this.state.fils.stars}
                               onChange={this.changed}>
                          <option value="">Todo</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </FormGroup>
                      <FormGroup check className="mrg-r-10">
                        <Label check>
                          <Input type="checkbox"
                                 name="with_comment"
                                 value={this.state.fils.with_comment}
                                 onChange={this.changed}/> Con comentario
                        </Label>
                      </FormGroup>
                    </>
                  )}
                  ref={ins => this.fils = ins}
                  endpoint={this.props.match.url}
                  cols={[
                    {name: 'client_name', value: 'Usuario'},
                    {name: 'client_dni', value: 'DNI'},
                    {name: 'client_card_number', value: 'Tarjeta'},
                    {name: 'client_email', value: 'Correo electrónico'},
                    {name: 'stars', value: 'Estrellas'},
                    {name: 'comment', value: 'Comentario'},
                    {name: 'date_created', value: 'Fecha de creación', width: '1%'},
                    {width: '1%'}
                  ]}
                  row={(o, i) => (
                    <tr key={i}>
                      <td>{o.cl_name}</td>
                      <td>{o.cl_document}</td>
                      <td>{o.cl_card_number}</td>
                      <td>{o.cl_email}</td>
                      <td className="nowrap">
                        {Array.from({length: parseInt(o.stars)}, (stv, sti) => (
                          <i key={sti} className="fa fa-star mrg-r-5" style={{color: '#F8BD08'}}/>
                        ))}
                        {Array.from({length: 5 - parseInt(o.stars)}, (stv, sti) => (
                          <i key={sti} className="fa fa-star mrg-r-5" style={{color: '#DDD'}}/>
                        ))}
                      </td>
                      <td>{o.comment}</td>
                      <td className="nowrap">{o.date_created.datetime()}</td>
                      <td>
                      </td>
                    </tr>
                  )}
            />

          </CardBody>
        </Card>

      </div>)
  }
}
