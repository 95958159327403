import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import ReactCrop, {makeAspectCrop} from "react-image-crop";
import 'react-image-crop/lib/ReactCrop.scss';

export default class Cutter extends Component {

  state = {
    modal: false,
    src: null,
    crop: null,
    originalImage: null,
  };

  toggle = () => this.setState({
    modal: !this.state.modal,
  });

  open = (image) => {

    const reader = new FileReader();
    reader.addEventListener("load", () => this.setState({
      src: reader.result,
      originalImage: image,
      modal: true,
      crop: {
        aspect: 3 / 2,
      },
      finalImage: null,
    }), false);
    reader.readAsDataURL(image);

  };

  save = () => {
    this.setState({modal: false});

    if (typeof this.props.callback === 'function') {
      this.props.callback(this.state.finalImage || this.state.originalImage);
    }
  };

  onImageLoaded = async (image, pixelCrop) => {
    /*this.setState({image});
    if (pixelCrop) {
      const finalImage = await this.getCroppedImg(
        image,
        pixelCrop,
        "newFile.jpeg"
      );
      this.setState({finalImage});
    }*/
    this.setState({
      image: image,
      crop: { ...this.state.crop, x: 10, y: 10, width: 80 } // initial crop
    });
  };

  onCropComplete = async (crop, pixelCrop) => {
    const finalImage = await this.getCroppedImg(
      this.state.image,
      pixelCrop,
      'newFile.jpg'
    );
    this.setState({finalImage});
  };

  onCropChange = crop => {
    this.setState({crop});
  };

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        if (file) {
          file.name = fileName;
          resolve(file);
        }
      }, "image/jpeg");
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} backdrop="static" keyboard={false}>
        <ModalHeader toggle={this.toggle}>Recortar</ModalHeader>
        <ModalBody className="pdg-5">

          {this.state.src && (
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              keepSelection={true}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={this.toggle}>
            Cancelar
          </Button>
          <Button color="primary" onClick={this.save}>
            <i className="fa fa-check"/> Listo
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
