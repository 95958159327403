import React, {Component} from 'react';
import {
  Alert,
  Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader,
  Row
} from 'reactstrap';
import {Api} from "../../inc/api";
import {toast} from "react-toastify";
import Dropzone from "react-dropzone";

export default class M extends Component {

  _state = {
    loading: false,
    error  : null,
    modal  : false,
    item   : {
      type: '',
    },
    file   : null
  };

  state = {...this._state};

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  add = () => {
    this.setState({
      ...this._state,
      modal: true,
    });
  };

  save = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true,
      error  : null
    });

    const data = new FormData();
    Object.keys(this.state.item).map(key => data.append(key, this.state.item[key]));
    data.append('file', this.state.file);

    Api.post('/schedules/create', data, (rsp) => {

      if (rsp.ok) {
        toast.success('Guardado correctamente');
        this.toggle();
        if (typeof this.props.callback === 'function') this.props.callback();
      } else {
        this.setState({
          ...this.state,
          loading: false,
          error  : rsp.msg
        });
      }

    });
  };

  changed = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      item: {
        ...this.state.item,
        [name]: value
      }
    });
  };

  render() {
    const {item} = this.state;
    return (
      <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}>
        <fieldset disabled={this.state.loading}>
          <ModalHeader toggle={this.toggle}>
            Cargar horarios
          </ModalHeader>
          <ModalBody className="form-horizontal">

            <Alert color="danger" isOpen={this.state.error != null} fade={true}>
              {this.state.error}
            </Alert>

            <Row className="form-group">
              <Col md="3">Tipo</Col>
              <Col md="9">
                <Input
                  type="select"
                  name="type"
                  value={item.type}
                  onChange={this.changed}>
                  <option value="">Seleccionar</option>
                  <option value="1">LV (lunes a viernes)</option>
                  <option value="2">SAB (sábado)</option>
                  <option value="3">DOM (domingos y feriados)</option>
                </Input>
              </Col>
            </Row>

            <Row className="form-group">
              <Col md="3">Archivo</Col>
              <Col md="9">

                {this.state.file && <div>{this.state.file.name}</div>}

                <Dropzone
                  className="_dropzone"
                  activeClassName="active"
                  rejectClassName="error"
                  disabledClassName="disabled"
                  disabled={this.state.loading}
                  multiple={false}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onDrop={files => {
                    this.setState({
                      file: files[0]
                    });
                  }}>
                  <span className="btn btn-outline-dark">Examinar...</span>
                </Dropzone>

              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.toggle}
              outline>
              Cancelar
            </Button>
            <Button
              color="primary"
              onClick={this.save}
              disabled={!item.type || !this.state.file}>
              <i className="fa fa-check"/> Guardar
            </Button>
          </ModalFooter>
        </fieldset>
      </Modal>
    );
  }
}
