import Ots from "./views/ots";
import Clients from "./views/clients";
import Modules from "./views/modules";
//import ClientsForm from "./views/clients_form";
//import OtsForm from "./views/ots_form";
import Users from "./views/users";
//import UsersForm from "./views/users_form";
import E404 from "./views/e404";
import Managements from "./views/managements";
import Vehicles from "./views/vehicles";
import TypeServices from "./views/type_services";
import Competences from "./views/competences";
import Consumables from "./views/consumables";
import Materials from "./views/materials";
import DocumentSigs_horario from "./views/horarios";
import DocumentSigs_saldos from "./views/balances";
import Personals from "./views/personals";
import Events from "./views/events";
import Kits from "./views/kits";
import Roles from "./views/roles";
import Settings from "./views/settings";
import Proveedores from "./views/proveedores";
import Tipo_gastos from "./views/tipo_gastos";
import Unidades from "./views/unidades";

import Choferes from "./views/choferes";
import Rutas from "./views/rutas";
import Articles from "./views/articles";
import Ratings from "./views/ratings";
import Schedules from "./views/schedules/Schedules";

const routes = [
  {path: '/404', component: E404},
  {path: '/ots', component: Ots},
  {path: '/users', component: Users},
  {path: '/clients', component: Clients},

  {path: '/proveedores', component: Proveedores},
  {path: '/tipo_gastos', component: Tipo_gastos},
  {path: '/unidades', component: Unidades},

  {path: '/choferes', component: Choferes},
  {path: '/rutas', component: Rutas},

  {path: '/articles', component: Articles},
  {path: '/ratings', component: Ratings},

  {path: '/managements', component: Managements},
  {path: '/vehicles', component: Vehicles},
  {path: '/type_services', component: TypeServices},
  {path: '/competences', component: Competences},
  {path: '/consumables', component: Consumables},
  {path: '/materials', component: Materials},
  {path: '/horarios', component: DocumentSigs_horario},
  {path: '/balances', component: DocumentSigs_saldos},
  {path: '/personals', component: Personals},
  {path: '/events', component: Events},
  {path: '/kits', component: Kits},
  {path: '/modules', component: Modules},
  {path: '/roles', component: Roles},
  {path: '/settings', component: Settings},
  {path: '/schedules', component: Schedules},
];

export default routes;
