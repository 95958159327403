import React from 'react';
import {
  Button, Card, CardBody, CardHeader, FormGroup, Input, Label
} from 'reactstrap';
import Fils from "../widgets/fils";
import SchedulesM from "./SchedulesM";
import {Api} from "../../inc/api";

export default class Articles extends React.Component {

  state = {
    fils    : {
      date_from   : '',
      date_to     : '',
      stars       : '',
      with_comment: false,
    },
    stations: [],
    types   : [],
  };

  componentDidMount() {
    this.loadFils();
  }

  loadFils() {
    Api.get('/schedules/fils', rsp => {
      if (rsp.ok) {
        this.setState({
          stations: rsp.stations,
          types   : rsp.types,
        });
      }
    });
  }

  changed = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      fils: {
        ...this.state.fils,
        [name]: value
      }
    });
  };

  render() {
    return (
      <div>
        <Card className="medi">
          <CardHeader>
            <span className="text-uppercase bold">Horarios</span>
            <div className="card-header-actions">
              <button className="bold text-uppercase btn btn-primary" onClick={() => this.modal.add()}>
                <i className="fa fa-plus"/> Nuevo
              </button>
            </div>
          </CardHeader>
          <CardBody className="pdg-5">

            <Fils {...this.props}
                  ref={ins => this.fils = ins}
                  endpoint={this.props.match.url}
                  customFils={this.state.fils}
                  notWord
                  form={() => (
                    <>
                      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Input type="select"
                               name="id_station"
                               value={this.state.fils.id_station}
                               onChange={this.changed}>
                          <option value="">- Estación -</option>
                          {this.state.stations.map((o, i) => <option key={i} value={o.id}>{o.name}</option>)}
                        </Input>
                      </FormGroup>
                      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Input type="select"
                               name="type"
                               value={this.state.fils.type}
                               onChange={this.changed}>
                          <option value="">- Tipo -</option>
                          {this.state.types.map((o, i) => <option key={i} value={o.id}>{o.name}</option>)}
                        </Input>
                      </FormGroup>
                    </>
                  )}
                  cols={[
                    {name: 'st_name', value: 'Estación'},
                    {name: 'type_human', value: 'Tipo', width: '1%'},
                    {name: 'time1', value: 'Norte', width: '1%'},
                    {name: 'time2', value: 'Sur', width: '1%'},
                    {name: 'date_created', value: 'Fecha de creación', width: '1%'},
                  ]}
                  row={(o, i) => (
                    <tr key={i}>
                      <td>{o.st_name}</td>
                      <td>{o.type_human}</td>
                      <td className="nowrap">{o.time1}</td>
                      <td className="nowrap">{o.time2}</td>
                      <td className="nowrap">{o.date_created.datetime()}</td>
                    </tr>
                  )}
            />

          </CardBody>
        </Card>

        <SchedulesM ref={ins => this.modal = ins}
                    callback={() => this.fils.loadData()}/>

      </div>)
  }
}
